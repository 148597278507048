import React, {useEffect, useState, useRef} from "react";
import {BrowserRouter as Router } from "react-router-dom";
import { connect, useDispatch } from "react-redux";

import TrialService from "./TrialService";
import InternationalisationService from "./InternationalisationService";
import AuthService from "./services/AuthService";
import TrialContext from "./context/TrialContext";
import ConfigContext from "./context/ConfigContext";
import UserContext from "./context/UserContext";
import GroupsContext from "./context/GroupsContext";
import Dashboard from "./components/dashboard/Dashboard";
import RootRoutes from "./navigators/RootRoutes";
import AppLoader from "./AppLoader";
import SignatureModal from "./questionnaires/SignatureModal";
import AppStateService from "./services/AppStateService";
import _ from "lodash";
import SubjectAppStateService from "./services/SubjectAppStateService";
import { getDefinitions } from "./redux/questionnaires/questionnaireDefinitionsSlice";
import { compose } from "redux";
import LocalDataService from "./services/LocalDataService";
import {  initFirebase, logevent } from "./services/FirebaseAnalytics";
import { FullPageLayoutProvider } from "./hooks/useFullPageLayoutPortal";
import { FullScreenProvider } from "./context/FullScreenContext";
import {SubjectContextProvider} from "./hooks/useSubjectContext";
import { getQuestionnairesCount } from "./redux/questionnaires/subjectQuestionnairesSlice";
import KeyboardHotkeyMappings from "./utility/KeyboardHotkeyMappings";

const PRIORITY_REFRESH_TIME = 5000;
const DEFAULT_REFRESH_TIME = 60000;
const PRIORITY_REFRESH_LIMIT = 60;

const AppRoot = (props) => {
  const { definitions, allQuestionnaireCount } = props;
  const dispatch = useDispatch();

  const defaultRefreshInterval = useRef(null);
  const priorityRefreshInterval = useRef(null);
  const priorityRefreshCounter = useRef(0);

  // Generally it is not good to store auth information in state as it can be easilty
  // changed but we will be recalculating it any time something changes
  const [profile, setProfile] = useState(null);
  const [groups, setGroups] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(AuthService.isLoggedIn());
  const refreshAuthState = async () => {
    let newLoggedStatus = AuthService.isLoggedIn();

    if (newLoggedStatus) {
      try {
        const newProfile = await AuthService.getMyProfile();
        const newAccountType = await AuthService.getAccountType();
        if (newProfile === undefined || newProfile === null) {
          throw new Error("profile not found");
        }

        if (
          !_.isEqual(
            _.omit(profile, ["groupMappings"]),
            _.omit(newProfile, ["groupMappings"])
          )
        ) {
          await setAccountType(newAccountType);
          await setProfile(newProfile);
        }
      } catch (error) {
        AuthService.logout();
        throw new Error("failure to get profile");
      }

      try {
        let groups = await TrialService.getGroups();
        setGroups(groups);
      } catch (error) {
        console.log("Group Request Failed");
        throw new Error("group request failed");
      }
    }

    if (isLoggedIn !== newLoggedStatus) {
      setIsLoggedIn(newLoggedStatus);
    }
  };

  useEffect(() => {
    if (allQuestionnaireCount > 0) {
      stopPriorityRefresh();
      setupDefaultRefreshStartTimeout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allQuestionnaireCount]);

  useEffect(() => {
    if (priorityRefreshCounter.current >= PRIORITY_REFRESH_LIMIT) {
      stopPriorityRefresh();
      setupDefaultRefreshStartTimeout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priorityRefreshCounter.current]);

  const refresh = () => {
    if (profile && accountType === "subject") {
      //Active Questionnaires
      SubjectAppStateService.getSubjectQuestionnairesFromServer(dispatch);
      //Completed Questionnaires
      SubjectAppStateService.getSubjectCompletedQuestionnairesFromServer(dispatch);
    }
  };

  const performPriorityRefresh = () => {
    priorityRefreshCounter.current++;
    refresh();
  };

  const setupPriorityRefreshInterval = () => {
    performPriorityRefresh();

    clearInterval(priorityRefreshInterval.current);
    priorityRefreshCounter.current = 0;
    priorityRefreshInterval.current = setInterval(performPriorityRefresh, PRIORITY_REFRESH_TIME);
  };

  const performDefaultRefresh = () => {
    refresh();
  };

  const setupDefaultRefreshStartTimeout = () => {
    clearInterval(defaultRefreshInterval.current);
    defaultRefreshInterval.current = setInterval(performDefaultRefresh, DEFAULT_REFRESH_TIME);
  };

  const stopPriorityRefresh = () => {
    clearInterval(priorityRefreshInterval.current);
  };

  useEffect(() => {
    refreshAuthState();

    if (profile != null) {
      setupPriorityRefreshInterval();
    }

    return () => {
      clearInterval(defaultRefreshInterval.current);
      clearInterval(priorityRefreshInterval.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  // Set up language and trial config
  const [config, setConfig] = useState(null);
  const [accountType, setAccountType] = useState(null);
  const [trial, setTrial] = useState(null);
  const [appLoadError, setAppLoadError] = useState(false);

  const initialize = async () => {
    try {
      const config = await TrialService.getConfiguration();
      const trial = await TrialService.getCurrentTrial();

      //check if lang param is passed in, if it is try and use it
      if (
        window.location.search.includes("?lang=") ||
        window.location.search.includes("&lang=")
      ) {
        let queryStringLanguage = new URLSearchParams(
          window.location.search
        ).get("lang");
        if (queryStringLanguage) {
          LocalDataService.setLanguage(queryStringLanguage);
          //remove the query param as this would cause problems with a reload
          window.history.pushState(
            {},
            "",
            removeParam("lang", window.location.href)
          );
          window.location.reload(false);
        }
      } else {
        //if lang param is not supplied then look for previously set language
        //or default to the first from the list of configured languages
        let selectedLanguage = await InternationalisationService.getLanguage();
        if (selectedLanguage) {
          await checkForValidLanguage(selectedLanguage);
        } else {
          const allLanguages = await InternationalisationService.getLanguages();
          if (allLanguages.length) {
            await InternationalisationService.changeLanguage(
              allLanguages[0].code
            );
          }
        }
      }

      // TODO: I would like to provide config through context, but there are many that use window
      // best to make a ticket on its own.
      Window.configuration = config;

      await setConfig(config);
      await setTrial(trial);

      // Initialise Firebase
      initFirebase();
      logevent('clinictian_web_initialised');
      AppStateService.getQuestionnaireDefinitionsFromServer(dispatch);
      await AppStateService.fetchLocalCachedState(dispatch);
      AppStateService.pollForLocalStateChanges(dispatch);
    } catch (e) {
      console.error("[AppRoot] App Load Error", e);
      setAppLoadError(true);
    }
  };

  useEffect(() => {
    initialize();
    return () => {
      KeyboardHotkeyMappings.unAssignKeys();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      KeyboardHotkeyMappings.assignKeys();
    }
  }, [isLoggedIn]);

  const refreshTrial = async () => {
    const refreshTrialCache = true;
    setTrial(await TrialService.getCurrentTrial(refreshTrialCache));
  }

  return (
    <>
      {(!config || !trial) && <AppLoader timeout={5000} />}
      {appLoadError && <div>ERROR</div>}
      {config && trial && definitions.length > 0 && (
        <TrialContext.Provider value={{trial, refreshTrial}}>
          <ConfigContext.Provider value={config}>
            <UserContext.Provider
              value={{ isLoggedIn, profile, accountType, refreshAuthState }}
            >
              <SubjectContextProvider>
                <GroupsContext.Provider value={groups}>
                  <FullPageLayoutProvider>
                    <FullScreenProvider>
                      <Router>
                        <Dashboard>
                          <RootRoutes loggedIn={isLoggedIn} />
                        </Dashboard>
                      </Router>
                    </FullScreenProvider>
                  </FullPageLayoutProvider>
                  <SignatureModal isGlobalInstance />
                </GroupsContext.Provider>
              </SubjectContextProvider>
            </UserContext.Provider>
          </ConfigContext.Provider>
        </TrialContext.Provider>
      )}
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    definitions: getDefinitions(store) ,
    allQuestionnaireCount: getQuestionnairesCount(store)
  };
};

const checkForValidLanguage = async (languageQueryParam) => {
  const languages = await InternationalisationService.getLanguages();
  for (let language of languages) {
    if (language.code === languageQueryParam) {
      InternationalisationService.changeLanguage(language.code);
      return true;
    }
  }
  //if we make it here then a language has not been matched, replace with default value
  InternationalisationService.changeLanguage(languages[0].code);
  return false;
};

const removeParam = (parameter, url) => {
  var urlparts = url.split("?");
  if (urlparts.length >= 2) {
    var prefix = encodeURIComponent(parameter) + "=";
    var pars = urlparts[1].split(/[&;]/g);
    for (var i = pars.length; i-- > 0; ) {
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }
    return urlparts[0] + (pars.length > 0 ? "?" + pars.join("&") : "");
  }
  return url;
};

const enhance = compose(connect(mapStateToProps));

export default enhance(AppRoot);
